var _excluded = ["className", "color", "size", "position", "max", "valueText", "label", "value", "labelProps"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { EuiI18n } from '../i18n';
import { EuiInnerText } from '../inner_text';
import { isNil } from '../../services/predicate';
import { useEuiTheme, makeHighContrastColor } from '../../services';
import { euiProgressStyles, euiProgressDataStyles, euiProgressLabelStyles, euiProgressValueTextStyles } from './progress.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SIZES = ['xs', 's', 'm', 'l'];
export var COLORS = ['primary', 'success', 'warning', 'danger', 'subdued', 'accent', 'vis0', 'vis1', 'vis2', 'vis3', 'vis4', 'vis5', 'vis6', 'vis7', 'vis8', 'vis9'];
export var POSITIONS = ['fixed', 'absolute', 'static'];
export var EuiProgress = function EuiProgress(_ref) {
  var className = _ref.className,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'success' : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'm' : _ref$size,
      _ref$position = _ref.position,
      position = _ref$position === void 0 ? 'static' : _ref$position,
      max = _ref.max,
      _ref$valueText = _ref.valueText,
      valueText = _ref$valueText === void 0 ? false : _ref$valueText,
      label = _ref.label,
      value = _ref.value,
      labelProps = _ref.labelProps,
      rest = _objectWithoutProperties(_ref, _excluded);

  var determinate = !isNil(max);
  var isNamedColor = COLORS.includes(color);
  var euiTheme = useEuiTheme();
  var customColorStyles = !isNamedColor ? {
    color: color
  } : {};
  var customTextColorStyles = !isNamedColor ? {
    color: makeHighContrastColor(color)(euiTheme.euiTheme)
  } : {};
  var styles = euiProgressStyles(euiTheme, determinate);
  var cssStyles = [styles.euiProgress, determinate && styles.native, !determinate && styles.indeterminate, styles[size], styles[position], isNamedColor ? styles[color] : styles.customColor];
  var dataStyles = euiProgressDataStyles(euiTheme);
  var dataCssStyles = [dataStyles.euiProgress__data, size === 'l' && dataStyles[size]];
  var labelCssStyles = [euiProgressLabelStyles.euiProgress__label];
  var valueTextStyles = euiProgressValueTextStyles(euiTheme);
  var valueTextCssStyles = [valueTextStyles.euiProgress__valueText, isNamedColor ? valueTextStyles[color] : styles.customColor];
  var classes = classNames('euiProgress', className);
  var labelClasses = classNames('euiProgress__label', labelProps === null || labelProps === void 0 ? void 0 : labelProps.className);
  var valueRender;

  if (valueText === true) {
    // valueText is true
    valueRender = ___EmotionJSX(EuiI18n, {
      token: "euiProgress.valueText",
      default: "{value}%",
      values: {
        value: value
      }
    });
  } else if (valueText) {
    // valueText exists
    valueRender = valueText;
  } // Because of a Firefox animation issue, indeterminate progress needs to not use <progress />.
  // See https://css-tricks.com/html5-progress-element/


  if (determinate) {
    return ___EmotionJSX(Fragment, null, label || valueText ? ___EmotionJSX("div", {
      css: dataCssStyles,
      className: "euiProgress__data"
    }, label && ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
      return ___EmotionJSX("span", _extends({
        title: innerText,
        ref: ref
      }, labelProps, {
        css: labelCssStyles,
        className: labelClasses
      }), label);
    }), valueRender && ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
      return ___EmotionJSX("span", {
        title: innerText,
        ref: ref,
        style: customTextColorStyles,
        css: valueTextCssStyles,
        className: "euiProgress__valueText"
      }, valueRender);
    })) : undefined, ___EmotionJSX("progress", _extends({
      css: cssStyles,
      className: classes,
      style: customColorStyles,
      max: max,
      value: value,
      "aria-hidden": label && valueText ? true : false
    }, rest)));
  } else {
    return ___EmotionJSX("div", _extends({
      css: cssStyles,
      style: customColorStyles,
      className: classes
    }, rest));
  }
};
EuiProgress.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  size: PropTypes.any,

  /**
     * One of EUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     */

  /**
     * One of EUI's color palette, vis colors or a valid CSS color value https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
     */
  color: PropTypes.oneOfType([PropTypes.any.isRequired, PropTypes.any.isRequired]),
  position: PropTypes.any,
  max: PropTypes.number,

  /*
       * If true, will render the percentage, otherwise pass a custom node
       */
  valueText: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.node.isRequired]),
  label: PropTypes.node,

  /**
       * Object of props passed to the <span/> wrapping the determinate progress's label
       */
  labelProps: PropTypes.any
};