/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
export var ENTER = 'Enter';
export var SPACE = ' ';
export var ESCAPE = 'Escape';
export var TAB = 'Tab';
export var BACKSPACE = 'Backspace';
export var F2 = 'F2';
export var ALT = 'Alt';
export var SHIFT = 'Shift';
export var CTRL = 'Control';
export var META = 'Meta'; // Windows, Command, Option

export var ARROW_DOWN = 'ArrowDown';
export var ARROW_UP = 'ArrowUp';
export var ARROW_LEFT = 'ArrowLeft';
export var ARROW_RIGHT = 'ArrowRight';
export var PAGE_UP = 'PageUp';
export var PAGE_DOWN = 'PageDown';
export var END = 'End';
export var HOME = 'Home';
export var keys;

(function (keys) {
  keys["ENTER"] = "Enter";
  keys["SPACE"] = " ";
  keys["ESCAPE"] = "Escape";
  keys["TAB"] = "Tab";
  keys["BACKSPACE"] = "Backspace";
  keys["F2"] = "F2";
  keys["ALT"] = "Alt";
  keys["SHIFT"] = "Shift";
  keys["CTRL"] = "Control";
  keys["META"] = "Meta";
  keys["ARROW_DOWN"] = "ArrowDown";
  keys["ARROW_UP"] = "ArrowUp";
  keys["ARROW_LEFT"] = "ArrowLeft";
  keys["ARROW_RIGHT"] = "ArrowRight";
  keys["PAGE_UP"] = "PageUp";
  keys["PAGE_DOWN"] = "PageDown";
  keys["END"] = "End";
  keys["HOME"] = "Home";
})(keys || (keys = {}));