var _excluded = ["breadcrumbs", "className", "responsive", "truncate", "max", "type", "lastBreadcrumbIsCurrentPage"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiI18n } from '../i18n';
import { useEuiTheme, useCurrentEuiBreakpoint } from '../../services';
import { EuiBreadcrumb, EuiBreadcrumbContent, EuiBreadcrumbCollapsed } from './breadcrumb';
import { euiBreadcrumbsListStyles } from './breadcrumbs.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var responsiveDefault = {
  xs: 1,
  s: 2,
  m: 4
};
export var EuiBreadcrumbs = function EuiBreadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      className = _ref.className,
      _ref$responsive = _ref.responsive,
      responsive = _ref$responsive === void 0 ? responsiveDefault : _ref$responsive,
      _ref$truncate = _ref.truncate,
      truncate = _ref$truncate === void 0 ? true : _ref$truncate,
      _ref$max = _ref.max,
      max = _ref$max === void 0 ? 5 : _ref$max,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'page' : _ref$type,
      _ref$lastBreadcrumbIs = _ref.lastBreadcrumbIsCurrentPage,
      lastBreadcrumbIsCurrentPage = _ref$lastBreadcrumbIs === void 0 ? true : _ref$lastBreadcrumbIs,
      rest = _objectWithoutProperties(_ref, _excluded);

  var ariaLabel = useEuiI18n('euiBreadcrumbs.nav.ariaLabel', 'Breadcrumbs');
  var euiTheme = useEuiTheme();
  var breadcrumbsListStyles = euiBreadcrumbsListStyles(euiTheme);
  var cssBreadcrumbsListStyles = [breadcrumbsListStyles.euiBreadcrumbs__list, truncate && breadcrumbsListStyles.isTruncated];
  var responsiveMax = useResponsiveMax(responsive, max);
  var visibleBreadcrumbs = useMemo(function () {
    var shouldCollapseBreadcrumbs = responsiveMax && breadcrumbs.length > responsiveMax;
    return shouldCollapseBreadcrumbs ? limitBreadcrumbs(breadcrumbs, responsiveMax) : breadcrumbs;
  }, [breadcrumbs, responsiveMax]);
  var breadcrumbChildren = useMemo(function () {
    return visibleBreadcrumbs.map(function (breadcrumb, index) {
      var isFirstBreadcrumb = index === 0;
      var isLastBreadcrumb = index === visibleBreadcrumbs.length - 1;
      var isOnlyBreadcrumb = visibleBreadcrumbs.length === 1;
      var sharedProps = {
        type: type,
        truncate: truncate
      };
      return breadcrumb.isCollapsedButton ? ___EmotionJSX(EuiBreadcrumbCollapsed, _extends({
        key: "collapsed"
      }, sharedProps, {
        isFirstBreadcrumb: isFirstBreadcrumb
      }), ___EmotionJSX(EuiBreadcrumbs, {
        breadcrumbs: breadcrumb.overflowBreadcrumbs,
        lastBreadcrumbIsCurrentPage: false,
        responsive: false,
        truncate: false,
        max: 0
      })) : ___EmotionJSX(EuiBreadcrumb, _extends({
        key: index
      }, sharedProps), ___EmotionJSX(EuiBreadcrumbContent, _extends({
        isFirstBreadcrumb: isFirstBreadcrumb,
        isLastBreadcrumb: isLastBreadcrumb,
        isOnlyBreadcrumb: isOnlyBreadcrumb,
        highlightLastBreadcrumb: isLastBreadcrumb && lastBreadcrumbIsCurrentPage
      }, sharedProps, breadcrumb)));
    });
  }, [visibleBreadcrumbs, truncate, type, lastBreadcrumbIsCurrentPage]);
  return ___EmotionJSX("nav", _extends({
    "aria-label": ariaLabel,
    className: classNames('euiBreadcrumbs', className)
  }, rest), ___EmotionJSX("ol", {
    className: "euiBreadcrumbs__list",
    css: cssBreadcrumbsListStyles
  }, breadcrumbChildren));
};
EuiBreadcrumbs.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,

  /**
     * Hides extra (above the max) breadcrumbs under a collapsed item as the window gets smaller.
     * Pass a custom #EuiBreadcrumbResponsiveMaxCount object to change the number of breadcrumbs to show at the particular breakpoints.
     *
     * Pass `false` to turn this behavior off.
     *
     * Default: `{ xs: 1, s: 2, m: 4 }`
     */
  responsive: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.any.isRequired]),

  /**
     * Forces all breadcrumbs to single line and
     * truncates each breadcrumb to a particular width,
     * except for the last item
     */
  truncate: PropTypes.bool,

  /**
     * Collapses the inner items past the maximum set here
     * into a single ellipses item.
     * Omitting or passing a `0` value will show all breadcrumbs.
     */
  max: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.oneOf([null])]),

  /**
     * The array of individual #EuiBreadcrumb items
     */
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    "aria-label": PropTypes.string,
    "data-test-subj": PropTypes.string,
    href: PropTypes.string,
    rel: PropTypes.string,
    onClick: PropTypes.func,

    /**
         * Visible label of the breadcrumb
         */
    text: PropTypes.node.isRequired,

    /**
         * Force a max-width on the breadcrumb text
         */
    truncate: PropTypes.bool,

    /**
         * Accepts any EuiLink `color` when rendered as one (has `href` or `onClick`)
         */
    color: PropTypes.any,

    /**
         * Override the existing `aria-current` which defaults to `page` for the last breadcrumb
         */
    "aria-current": PropTypes.any
  }).isRequired).isRequired,

  /**
     * Determines breadcrumbs appearance, with `page` being the default styling.
     * Application breadcrumbs should only be once per page, in (e.g.) EuiHeader
     */
  type: PropTypes.oneOf(["page", "application"]),

  /**
     * Whether the last breadcrumb should visually (and accessibly, to screen readers)
     * be highlighted as the current page. Defaults to true.
     */
  lastBreadcrumbIsCurrentPage: PropTypes.bool
};
export var useResponsiveMax = function useResponsiveMax(responsive, max) {
  // Use the default object if they simply passed `true` for responsive
  var responsiveObject = _typeof(responsive) === 'object' ? responsive : responsiveDefault; // The max property collapses any breadcrumbs past the max quantity.
  // This is the same behavior we want for responsiveness.
  // So calculate the max value based on the combination of `max` and `responsive`

  var responsiveMax = max; // Set the calculated max to the number associated with the currentBreakpoint key if it exists

  var currentBreakpoint = useCurrentEuiBreakpoint();

  if (responsive && currentBreakpoint && responsiveObject[currentBreakpoint]) {
    responsiveMax = responsiveObject[currentBreakpoint];
  } // Final check is to make sure max is used over a larger breakpoint value


  if (max && responsiveMax) {
    responsiveMax = max < responsiveMax ? max : responsiveMax;
  }

  return responsiveMax;
};
export var limitBreadcrumbs = function limitBreadcrumbs(breadcrumbs, max) {
  var breadcrumbsAtStart = [];
  var breadcrumbsAtEnd = [];
  var limit = Math.min(max, breadcrumbs.length);
  var start = Math.floor(limit / 2);
  var overflowBreadcrumbs = breadcrumbs.slice(start, start + breadcrumbs.length - limit);

  for (var i = 0; i < limit; i++) {
    // We'll alternate with displaying breadcrumbs at the end and at the start, but be biased
    // towards breadcrumbs the end so that if max is an odd number, we'll have one more
    // breadcrumb visible at the end than at the beginning.
    var isEven = i % 2 === 0; // We're picking breadcrumbs from the front AND the back, so we treat each iteration as a
    // half-iteration.

    var normalizedIndex = Math.floor(i * 0.5);
    var indexOfBreadcrumb = isEven ? breadcrumbs.length - 1 - normalizedIndex : normalizedIndex;
    var breadcrumb = breadcrumbs[indexOfBreadcrumb];

    if (isEven) {
      breadcrumbsAtEnd.unshift(breadcrumb);
    } else {
      breadcrumbsAtStart.push(breadcrumb);
    }
  }

  return [].concat(breadcrumbsAtStart, [{
    isCollapsedButton: true,
    overflowBreadcrumbs: overflowBreadcrumbs
  }], breadcrumbsAtEnd);
};