/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import { euiLineHeightFromBaseline, euiFontSizeFromScale } from '../functions/typography';
import { useEuiTheme } from '../../services/theme/hooks';

/**
 * Returns font-size and line-height
 */
export var euiFontSize = function euiFontSize(_ref, scale, options) {
  var euiTheme = _ref.euiTheme;
  return {
    fontSize: euiFontSizeFromScale(scale, euiTheme, options),
    lineHeight: euiLineHeightFromBaseline(scale, euiTheme, options)
  };
};
export var useEuiFontSize = function useEuiFontSize(scale, options) {
  var euiTheme = useEuiTheme();
  return euiFontSize(euiTheme, scale, options);
};
/**
 * Force text to wrap on natural word breaks (e.g. spaces & hyphens)
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */

export var euiTextBreakWord = function euiTextBreakWord() {
  return "\n  overflow-wrap: break-word !important; // makes sure the long string will wrap and not bust out of the container\n  word-wrap: break-word !important; // spec says, they are literally just alternate names for each other but some browsers support one and not the other\n  word-break: break-word; // IE doesn't understand but that's ok\n";
};
/**
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis.
 */

export var euiTextTruncate = function euiTextTruncate() {
  var maxWidth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '100%';
  return "\n  max-width: ".concat(maxWidth, "; // Ensure that the node has a maximum width after which truncation can occur\n  overflow: hidden !important;\n  text-overflow: ellipsis !important;\n  white-space: nowrap !important;\n");
};
/**
 * Fixed-width numbers for tabular data
 */

export var euiNumberFormat = function euiNumberFormat(_ref2) {
  var euiTheme = _ref2.euiTheme;
  return "\n  font-feature-settings: ".concat(euiTheme.font.featureSettings, ", 'tnum' 1;\n");
};
export var useEuiNumberFormat = function useEuiNumberFormat() {
  var euiTheme = useEuiTheme();
  return euiNumberFormat(euiTheme);
};