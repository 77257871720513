/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import { useEuiTheme } from '../../services';

/**
 * It is best practice to utilize the browser's default `outline` property for handling focus rings.
 * However, some components need to be forced to have the same behavior, or adjust the display.
 * This function re-applies the same default outline with a couple parameters
 * @param euiTheme UseEuiTheme.euiTheme
 * @param offset Accepts a specific measurement or 'inset', 'outset' or 'center' to adjust outline position
 * @param color Accepts any CSS color, **Note: only works in -webkit-**
 */
export var euiFocusRing = function euiFocusRing(euiTheme) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'center';
  var options = arguments.length > 2 ? arguments[2] : undefined;
  // Width is enforced as a constant at the global theme layer
  var outlineWidth = euiTheme.focus.width;
  var outlineColor = (options === null || options === void 0 ? void 0 : options.color) || euiTheme.focus.color;
  var outlineOffset = offset;

  if (offset === 'inset') {
    outlineOffset = "-".concat(outlineWidth);
  } else if (offset === 'outset') {
    outlineOffset = "".concat(outlineWidth);
  } else if (offset === 'center') {
    outlineOffset = "calc(".concat(outlineWidth, " / -2);");
  } // This function utilizes `focus-visible` to turn on focus outlines.
  // But this is browser-dependend:
  // 👉 Safari and Firefox innately respect only showing the outline with keyboard only
  // 💔 But they don't allow coloring of the 'auto'/default outline, so contrast is no good in dark mode.
  // 👉 For these browsers we use the solid type in order to match with `currentColor.
  // 😦 Which does means the outline will be square


  return "\n    outline: ".concat(outlineWidth, " solid ").concat(outlineColor, ";\n    outline-offset: ").concat(outlineOffset, ";\n\n    // \uD83D\uDC40 Chrome respects :focus-visible and allows coloring the `auto` style\n    &:focus-visible {\n      outline-style: auto;\n    }\n\n    // \uD83D\uDE45\u200D\u2640\uFE0F But Chrome also needs to have the outline forcefully removed from regular `:focus` state\n    &:not(:focus-visible) {\n      outline: none;\n    }\n  ");
};
export var useEuiFocusRing = function useEuiFocusRing(offset, color) {
  var _useEuiTheme = useEuiTheme(),
      euiTheme = _useEuiTheme.euiTheme;

  return euiFocusRing(euiTheme, offset, {
    color: color
  });
};