/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

/**
 * A constant storing the `prefers-reduced-motion` media query
 * so that when it is turned off, animations are not run.
 */
export var euiCanAnimate = '@media screen and (prefers-reduced-motion: no-preference)';
/**
 * A constant storing the `prefers-reduced-motion` media query that will
 * only apply the content if the setting is off (reduce).
 */

export var euiCantAnimate = '@media screen and (prefers-reduced-motion: reduce)';
/**
 * Speeds / Durations / Delays
 */

export var EuiThemeAnimationSpeeds = ['extraFast', 'fast', 'normal', 'slow', 'extraSlow'];

/**
 * Easings / Timing functions
 */
export var EuiThemeAnimationEasings = ['bounce', 'resistance'];