/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */

/**
 * Calculates the font-size value based on the provided scale key
 * @param scale - The font scale key
 * @param theme - Requires the `base` and `font` keys
 * @param options - Optional parameters - see _FontScaleOptions
 *
 * @returns string - Calculated font-size value
 */
export function euiFontSizeFromScale(scale, _ref) {
  var base = _ref.base,
      font = _ref.font;

  var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref2$measurement = _ref2.measurement,
      measurement = _ref2$measurement === void 0 ? 'rem' : _ref2$measurement,
      customScale = _ref2.customScale;

  if (measurement === 'em') {
    return "".concat(font.scale[scale], "em");
  }

  var numerator = base * font.scale[scale];
  if (customScale) numerator *= font.scale[customScale];
  var denominator = base * font.scale[font.body.scale];
  return measurement === 'px' ? "".concat(numerator, "px") : "".concat((numerator / denominator).toFixed(4), "rem");
}
/**
 * Calculates the line-height to the closest multiple of the baseline
 * EX: A proper line-height for text is 1.5 times the font-size.
 *     If our base font size (euiFontSize) is 16, and our baseline is 4. To ensure the
 *     text stays on the baseline, we pass a multiplier to calculate a line-height.
 * @param scale - The font scale key
 * @param theme - Requires the `base` and `font` keys
 * @param options - Optional parameters - see _FontScaleOptions
 *
 * @returns string - Calculated line-height value aligned to baseline
 */

export function euiLineHeightFromBaseline(scale, _ref3) {
  var base = _ref3.base,
      font = _ref3.font;

  var _ref4 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref4$measurement = _ref4.measurement,
      measurement = _ref4$measurement === void 0 ? 'rem' : _ref4$measurement,
      customScale = _ref4.customScale;

  var baseline = font.baseline,
      lineHeightMultiplier = font.lineHeightMultiplier;
  var numerator = base * font.scale[scale];
  if (customScale) numerator *= font.scale[customScale];
  var denominator = base * font.scale[font.body.scale];

  var _lineHeightMultiplier = numerator <= base ? lineHeightMultiplier : lineHeightMultiplier * 0.833;

  if (measurement === 'em') {
    // Even though the line-height via `em` cannot be determined against the pixel baseline grid;
    // we will assume that typically larger scale font-sizes should have a shorter line-height;
    return _lineHeightMultiplier.toFixed(4).toString();
  }

  var pixelValue = Math.floor(Math.round(numerator * _lineHeightMultiplier) / baseline) * baseline;
  return measurement === 'px' ? "".concat(pixelValue, "px") : "".concat((pixelValue / denominator).toFixed(4), "rem");
}
/**
 * Text weight shifting
 *
 * When changing the font-weight based on the state of the component,
 * this mixin will ensure that the sizing is dependent on the boldest
 * weight so it doesn't shift sibling content.
 */

export var euiTextShift = function euiTextShift() {
  var fontWeight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'bold';
  var attribute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data-text';
  var euiTheme = arguments.length > 2 ? arguments[2] : undefined;
  return "\n  &::after {\n    display: block;\n    content: attr(".concat(attribute, ");\n    font-weight: ").concat(euiTheme.font.weight[fontWeight], ";\n    height: 0;\n    overflow: hidden;\n    visibility: hidden;\n  }");
};