var _excluded = ["children", "className", "size", "color"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { keysOf } from '../../common';
import { jsx as ___EmotionJSX } from "@emotion/react";
var colorToClassMap = {
  accent: null,
  subdued: 'euiNotificationBadge--subdued'
};
export var COLORS = keysOf(colorToClassMap);
var sizeToClassNameMap = {
  s: null,
  m: 'euiNotificationBadge--medium'
};
export var SIZES = keysOf(sizeToClassNameMap);
export var EuiNotificationBadge = function EuiNotificationBadge(_ref) {
  var children = _ref.children,
      className = _ref.className,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 's' : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'accent' : _ref$color,
      rest = _objectWithoutProperties(_ref, _excluded);

  var classes = classNames('euiNotificationBadge', sizeToClassNameMap[size], colorToClassMap[color], className);
  return ___EmotionJSX("span", _extends({
    className: classes
  }, rest), children);
};
EuiNotificationBadge.propTypes = {
  /**
     * ReactNode to render as this component's content
     */
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["s", "m"]),
  color: PropTypes.oneOf(["accent", "subdued"]),
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};