var _excluded = ["activePage", "itemsPerPage", "itemsPerPageOptions", "showPerPageOptions", "onChangeItemsPerPage", "onChangePage", "pageCount"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import { EuiButtonEmpty } from '../../button';
import { EuiContextMenuItem, EuiContextMenuPanel } from '../../context_menu';
import { EuiFlexGroup, EuiFlexItem } from '../../flex';
import { EuiPagination } from '../../pagination';
import { EuiPopover } from '../../popover';
import { EuiI18n } from '../../i18n';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiTablePagination = function EuiTablePagination(_ref) {
  var activePage = _ref.activePage,
      _ref$itemsPerPage = _ref.itemsPerPage,
      itemsPerPage = _ref$itemsPerPage === void 0 ? 50 : _ref$itemsPerPage,
      _ref$itemsPerPageOpti = _ref.itemsPerPageOptions,
      itemsPerPageOptions = _ref$itemsPerPageOpti === void 0 ? [10, 20, 50, 100] : _ref$itemsPerPageOpti,
      _ref$showPerPageOptio = _ref.showPerPageOptions,
      showPerPageOptions = _ref$showPerPageOptio === void 0 ? true : _ref$showPerPageOptio,
      _ref$onChangeItemsPer = _ref.onChangeItemsPerPage,
      onChangeItemsPerPage = _ref$onChangeItemsPer === void 0 ? function () {} : _ref$onChangeItemsPer,
      onChangePage = _ref.onChangePage,
      pageCount = _ref.pageCount,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isPopoverOpen = _useState2[0],
      setIsPopoverOpen = _useState2[1];

  var togglePopover = useCallback(function () {
    setIsPopoverOpen(function (isOpen) {
      return !isOpen;
    });
  }, []);
  var closePopover = useCallback(function () {
    setIsPopoverOpen(false);
  }, []);

  var button = ___EmotionJSX(EuiButtonEmpty, {
    size: "xs",
    color: "text",
    iconType: "arrowDown",
    iconSide: "right",
    "data-test-subj": "tablePaginationPopoverButton",
    onClick: togglePopover
  }, itemsPerPage === 0 ? ___EmotionJSX(EuiI18n, {
    token: "euiTablePagination.allRows",
    default: "Showing all rows"
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(EuiI18n, {
    token: "euiTablePagination.rowsPerPage",
    default: "Rows per page"
  }), ": ", itemsPerPage));

  var items = useMemo(function () {
    return itemsPerPageOptions.map(function (itemsPerPageOption) {
      return ___EmotionJSX(EuiContextMenuItem, {
        key: itemsPerPageOption,
        icon: itemsPerPageOption === itemsPerPage ? 'check' : 'empty',
        onClick: function onClick() {
          closePopover();
          onChangeItemsPerPage(itemsPerPageOption);
        },
        "data-test-subj": "tablePagination-".concat(itemsPerPageOption, "-rows")
      }, itemsPerPageOption === 0 ? ___EmotionJSX(EuiI18n, {
        token: "euiTablePagination.rowsPerPageOptionShowAllRows",
        default: "Show all rows"
      }) : ___EmotionJSX(EuiI18n, {
        token: "euiTablePagination.rowsPerPageOption",
        values: {
          rowsPerPage: itemsPerPageOption
        },
        default: "{rowsPerPage} rows"
      }));
    });
  }, [itemsPerPageOptions, itemsPerPage, onChangeItemsPerPage, closePopover]);

  var itemsPerPagePopover = ___EmotionJSX(EuiPopover, {
    button: button,
    isOpen: isPopoverOpen,
    closePopover: closePopover,
    panelPaddingSize: "none",
    anchorPosition: "upRight"
  }, ___EmotionJSX(EuiContextMenuPanel, {
    items: items
  }));

  return ___EmotionJSX(EuiFlexGroup, {
    justifyContent: "spaceBetween",
    alignItems: "center",
    responsive: false,
    wrap: true,
    gutterSize: "s",
    className: "eui-xScroll"
  }, ___EmotionJSX(EuiFlexItem, {
    grow: false
  }, showPerPageOptions && itemsPerPagePopover), ___EmotionJSX(EuiFlexItem, {
    grow: false
  }, itemsPerPage > 0 && ___EmotionJSX(EuiPagination, _extends({
    pageCount: pageCount,
    activePage: activePage,
    onPageClick: onChangePage
  }, rest))));
};
EuiTablePagination.propTypes = {
  /**
     * Option to completely hide the "Rows per page" selector.
     */
  showPerPageOptions: PropTypes.bool,

  /**
     * Current selection for "Rows per page".
     * Pass `0` to display the selected "Show all" option and hide the pagination.
     */
  itemsPerPage: PropTypes.number,

  /**
     * Custom array of options for "Rows per page".
     * Pass `0` as one of the options to create a "Show all" option.
     */
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number.isRequired),

  /**
     * Click handler that passes back selected `pageSize` number
     */
  onChangeItemsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,

  /**
     * Requires the `id` of the table being controlled
     */
  "aria-controls": PropTypes.string,
  "aria-label": PropTypes.string
};