var _excluded = ["children", "className", "type", "truncate"],
    _excluded2 = ["text", "truncate", "type", "href", "rel", "onClick", "className", "color", "isFirstBreadcrumb", "isLastBreadcrumb", "isOnlyBreadcrumb", "highlightLastBreadcrumb"];

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React, { useState } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { EuiInnerText } from '../inner_text';
import { EuiTextColor } from '../text';
import { EuiLink } from '../link';
import { EuiPopover } from '../popover';
import { EuiIcon } from '../icon';
import { useEuiI18n } from '../i18n';
import { euiBreadcrumbStyles, euiBreadcrumbContentStyles } from './breadcrumb.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EuiBreadcrumb = function EuiBreadcrumb(_ref) {
  var children = _ref.children,
      className = _ref.className,
      type = _ref.type,
      truncate = _ref.truncate,
      rest = _objectWithoutProperties(_ref, _excluded);

  var classes = classNames('euiBreadcrumb', className);
  var euiTheme = useEuiTheme();
  var styles = euiBreadcrumbStyles(euiTheme);
  var cssStyles = [styles.euiBreadcrumb, styles[type], truncate && styles.isTruncated];
  return ___EmotionJSX("li", _extends({
    className: classes,
    css: cssStyles
  }, rest), children);
};
EuiBreadcrumb.propTypes = {
  type: PropTypes.oneOf(["page", "application"]).isRequired,
  isFirstBreadcrumb: PropTypes.bool,
  isLastBreadcrumb: PropTypes.bool,
  isOnlyBreadcrumb: PropTypes.bool,
  highlightLastBreadcrumb: PropTypes.bool
};
export var EuiBreadcrumbContent = function EuiBreadcrumbContent(_ref2) {
  var text = _ref2.text,
      truncate = _ref2.truncate,
      type = _ref2.type,
      href = _ref2.href,
      rel = _ref2.rel,
      onClick = _ref2.onClick,
      className = _ref2.className,
      color = _ref2.color,
      isFirstBreadcrumb = _ref2.isFirstBreadcrumb,
      isLastBreadcrumb = _ref2.isLastBreadcrumb,
      isOnlyBreadcrumb = _ref2.isOnlyBreadcrumb,
      highlightLastBreadcrumb = _ref2.highlightLastBreadcrumb,
      rest = _objectWithoutProperties(_ref2, _excluded2);

  var classes = classNames('euiBreadcrumb__content', className);
  var euiTheme = useEuiTheme();
  var styles = euiBreadcrumbContentStyles(euiTheme);
  var cssStyles = [styles.euiBreadcrumb__content, styles[type], truncate && (isLastBreadcrumb ? styles.isTruncatedLast : styles.isTruncated)];

  if (type === 'application') {
    if (isOnlyBreadcrumb) {
      cssStyles.push(styles.applicationStyles.onlyChild);
    } else if (isFirstBreadcrumb) {
      cssStyles.push(styles.applicationStyles.firstChild);
    } else if (isLastBreadcrumb) {
      cssStyles.push(styles.applicationStyles.lastChild);
    }
  }

  var ariaCurrent = highlightLastBreadcrumb ? 'page' : undefined;
  return ___EmotionJSX(EuiInnerText, null, function (ref, innerText) {
    var title = innerText === '' ? undefined : innerText;
    return !href && !onClick ? ___EmotionJSX(EuiTextColor, {
      color: highlightLastBreadcrumb ? 'default' : 'subdued',
      cloneElement: true
    }, ___EmotionJSX("span", _extends({
      ref: ref,
      title: title,
      "aria-current": ariaCurrent,
      className: classes,
      css: cssStyles
    }, rest), text)) : ___EmotionJSX(EuiLink, _extends({
      ref: ref,
      title: title,
      "aria-current": ariaCurrent,
      className: classes,
      css: cssStyles,
      color: color || (highlightLastBreadcrumb ? 'text' : 'subdued'),
      onClick: onClick,
      href: href,
      rel: rel
    }, rest), text);
  });
};
EuiBreadcrumbContent.propTypes = {
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,

  /**
       * Visible label of the breadcrumb
       */
  text: PropTypes.node.isRequired,

  /**
       * Force a max-width on the breadcrumb text
       */
  truncate: PropTypes.bool,

  /**
       * Accepts any EuiLink `color` when rendered as one (has `href` or `onClick`)
       */
  color: PropTypes.any,

  /**
       * Override the existing `aria-current` which defaults to `page` for the last breadcrumb
       */
  "aria-current": PropTypes.any,
  type: PropTypes.oneOf(["page", "application"]).isRequired,
  isFirstBreadcrumb: PropTypes.bool,
  isLastBreadcrumb: PropTypes.bool,
  isOnlyBreadcrumb: PropTypes.bool,
  highlightLastBreadcrumb: PropTypes.bool
};
export var EuiBreadcrumbCollapsed = function EuiBreadcrumbCollapsed(_ref3) {
  var children = _ref3.children,
      isFirstBreadcrumb = _ref3.isFirstBreadcrumb,
      type = _ref3.type;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isPopoverOpen = _useState2[0],
      setIsPopoverOpen = _useState2[1];

  var euiTheme = useEuiTheme();
  var styles = euiBreadcrumbStyles(euiTheme);
  var cssStyles = [styles.isCollapsed];
  var ariaLabel = useEuiI18n('euiBreadcrumb.collapsedBadge.ariaLabel', 'See collapsed breadcrumbs');

  var ellipsisButton = ___EmotionJSX(EuiBreadcrumbContent, {
    "aria-label": ariaLabel,
    title: ariaLabel,
    onClick: function onClick() {
      return setIsPopoverOpen(!isPopoverOpen);
    },
    truncate: false,
    text: ___EmotionJSX(React.Fragment, null, "\u2026 ", ___EmotionJSX(EuiIcon, {
      type: "arrowDown",
      size: "s"
    })),
    isFirstBreadcrumb: isFirstBreadcrumb,
    type: type
  });

  return ___EmotionJSX(EuiBreadcrumb, {
    css: cssStyles,
    type: type
  }, ___EmotionJSX(EuiPopover, {
    button: ellipsisButton,
    isOpen: isPopoverOpen,
    closePopover: function closePopover() {
      return setIsPopoverOpen(false);
    }
  }, children));
};
EuiBreadcrumbCollapsed.propTypes = {
  type: PropTypes.oneOf(["page", "application"]).isRequired,
  isFirstBreadcrumb: PropTypes.bool,
  isLastBreadcrumb: PropTypes.bool,
  isOnlyBreadcrumb: PropTypes.bool,
  highlightLastBreadcrumb: PropTypes.bool
};