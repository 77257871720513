/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
var isElasticHost = /^([a-zA-Z0-9]+\.)*elastic\.co$/; // In order for the domain to be secure it needs to be in a parsable format,
// with the protocol of http: or https: and the host matching elastic.co or
// of one its subdomains

export var isDomainSecure = function isDomainSecure() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  try {
    var parsed = new URL(url);
    var protocolMatches = parsed.protocol === 'http:' || parsed.protocol === 'https:';
    var domainMatches = !!parsed.host.match(isElasticHost);
    return protocolMatches && domainMatches;
  } catch (e) {
    return false;
  }
};