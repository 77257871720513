var _excluded = ["className", "size", "margin"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { euiHorizontalRuleStyles } from './horizontal_rule.styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SIZES = ['full', 'half', 'quarter'];
export var MARGINS = ['none', 'xs', 's', 'm', 'l', 'xl', 'xxl'];
var marginToClassNameMap = {
  none: null,
  xs: 'marginXSmall',
  s: 'marginSmall',
  m: 'marginMedium',
  l: 'marginLarge',
  xl: 'marginXLarge',
  xxl: 'marginXXLarge'
};
export var EuiHorizontalRule = function EuiHorizontalRule(_ref) {
  var _classNames;

  var className = _ref.className,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'full' : _ref$size,
      _ref$margin = _ref.margin,
      margin = _ref$margin === void 0 ? 'l' : _ref$margin,
      rest = _objectWithoutProperties(_ref, _excluded);

  var euiTheme = useEuiTheme();
  var styles = euiHorizontalRuleStyles(euiTheme);
  var classes = classNames('euiHorizontalRule', (_classNames = {}, _defineProperty(_classNames, "euiHorizontalRule--".concat(size), size), _defineProperty(_classNames, "euiHorizontalRule--".concat(marginToClassNameMap[margin]), margin && margin !== 'none'), _classNames), className);
  var cssStyles = [styles.euiHorizontalRule, styles[size], styles[margin]];
  return ___EmotionJSX("hr", _extends({
    css: cssStyles,
    className: classes
  }, rest));
};
EuiHorizontalRule.propTypes = {
  /**
     * Defines the width of the HR.
     */
  size: PropTypes.any,
  margin: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};