var _excluded = ["children", "className", "paddingSize", "borderRadius", "color", "hasShadow", "hasBorder", "grow", "panelRef", "element"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/*
 * Copyright Elasticsearch B.V. and/or licensed to Elasticsearch B.V. under one
 * or more contributor license agreements. Licensed under the Elastic License
 * 2.0 and the Server Side Public License, v 1; you may not use this file except
 * in compliance with, at your election, the Elastic License 2.0 or the Server
 * Side Public License, v 1.
 */
import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { useEuiTheme } from '../../services';
import { useEuiBackgroundColorCSS, useEuiPaddingCSS, BACKGROUND_COLORS, PADDING_SIZES } from '../../global_styling';
import { keysOf } from '../common';
import { euiPanelStyles } from './panel.style';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SIZES = PADDING_SIZES; // Exported padding sizes and class names necessary for EuiPopover and EuiCard.
// Which currently will only maintain support for the original values until conversion.

var paddingSizeToClassNameMap = {
  none: null,
  s: 'paddingSmall',
  m: 'paddingMedium',
  l: 'paddingLarge'
};

var _SIZES = keysOf(paddingSizeToClassNameMap);

export var BORDER_RADII = ['none', 'm'];
export var COLORS = BACKGROUND_COLORS;
export var EuiPanel = function EuiPanel(_ref) {
  var children = _ref.children,
      className = _ref.className,
      _ref$paddingSize = _ref.paddingSize,
      paddingSize = _ref$paddingSize === void 0 ? 'm' : _ref$paddingSize,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? 'm' : _ref$borderRadius,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'plain' : _ref$color,
      _ref$hasShadow = _ref.hasShadow,
      hasShadow = _ref$hasShadow === void 0 ? true : _ref$hasShadow,
      _ref$hasBorder = _ref.hasBorder,
      hasBorder = _ref$hasBorder === void 0 ? false : _ref$hasBorder,
      _ref$grow = _ref.grow,
      grow = _ref$grow === void 0 ? true : _ref$grow,
      panelRef = _ref.panelRef,
      element = _ref.element,
      rest = _objectWithoutProperties(_ref, _excluded);

  var euiTheme = useEuiTheme(); // Shadows are only allowed when there's a white background (plain)

  var canHaveShadow = !hasBorder && color === 'plain';
  var canHaveBorder = color === 'plain' || color === 'transparent';
  var styles = euiPanelStyles(euiTheme);
  var cssStyles = [styles.euiPanel, grow && styles.grow, styles.radius[borderRadius], useEuiPaddingCSS()[paddingSize], useEuiBackgroundColorCSS()[color], canHaveShadow && hasShadow === true && styles.hasShadow, canHaveBorder && hasBorder === true && styles.hasBorder, rest.onClick && styles.isClickable];
  var classes = classNames('euiPanel', "euiPanel--".concat(color), _defineProperty({}, "euiPanel--".concat(paddingSizeToClassNameMap[paddingSize]), paddingSizeToClassNameMap[paddingSize]), className);

  if (rest.onClick && element !== 'div') {
    return ___EmotionJSX("button", _extends({
      ref: panelRef,
      className: classes,
      css: cssStyles
    }, rest), children);
  }

  return ___EmotionJSX("div", _extends({
    ref: panelRef,
    className: classes,
    css: cssStyles
  }, rest), children);
};
EuiPanel.propTypes = {
  element: PropTypes.oneOfType([PropTypes.oneOf(["button"]), PropTypes.oneOf(["div"])]),

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */

  /**
     * Adds a medium shadow to the panel;
     * Only works when `color="plain"`
     */
  hasShadow: PropTypes.bool,

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */

  /**
     * Adds a slight 1px border on all edges.
     * Only works when `color="plain | transparent"`
     */
  hasBorder: PropTypes.bool,

  /**
     * Padding for all four sides
     */

  /**
     * Padding for all four sides
     */
  paddingSize: PropTypes.any,

  /**
     * Corner border radius
     */

  /**
     * Corner border radius
     */
  borderRadius: PropTypes.any,

  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */

  /**
     * When true the panel will grow in height to match `EuiFlexItem`
     */
  grow: PropTypes.bool,
  panelRef: PropTypes.any,

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */

  /**
     * Background color of the panel;
     * Usually a lightened form of the brand colors
     */
  color: PropTypes.any,
  className: PropTypes.string,
  "aria-label": PropTypes.string,
  "data-test-subj": PropTypes.string
};